'use client';
import BartursIcon from '@/components/Icons/BartursIcon';
import notify from '@/lib/helpers/notify';
import { useRouter } from '@/navigation';
import classNames from 'classnames';
import { useSearchParams } from 'next/navigation';
import { ChangeEvent, FormEvent, useRef, useState } from 'react';

export default function SimpleLogin() {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const params = useSearchParams();
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPassword(value);
    setError('');
  };

  const timeoutRef = useRef<NodeJS.Timeout | undefined>();

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsLoading(true);

    try {
      const response = await fetch(process.env.NEXT_PUBLIC_URL + '/api/simpleAuth', {
        method: 'POST',
        body: JSON.stringify({
          Password: password,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok && response.status === 200) {
        router.push(params.get('next') || '/');
      } else {
        notify('Senha inválida. Tente novamente.', 'error');
        setError('Senha inválida. Tente novamente.');

        // Clear any existing timeout
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }

        // Introduce a delay of 1000ms after handling the error
        timeoutRef.current = setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      }
    } catch (er: any) {
      console.error(er);
    } finally {
      // Ensure isLoading is set to false in case of success or unexpected error
      if (isLoading) {
        // Clear any existing timeout
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }

        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      }
    }
  };

  return (
    <div className="flex h-screen w-full flex-1 flex-col justify-center items-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto w-full relative">
        <BartursIcon className="w-60 md:w-72 mx-auto fill-primary" />
      </div>

      <div className="mt-10 w-60 md:w-72">
        <form className="space-y-6 " onSubmit={handleSubmit}>
          <div className="">
            <div className="flex items-center justify-between">
              <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                Senha
              </label>
            </div>
            <div className="mt-2">
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                value={password}
                onChange={handleChange}
                disabled={isLoading}
                required
                className={classNames(
                  isLoading && 'cursor-not-allowed',
                  'block w-full rounded-md border-0 pl-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:ring-primary focus-visible:outline focus-visible:outline-primary sm:text-sm sm:leading-6',
                )}
              />
            </div>
            <div className="w-full mt-2">
              <p className="text-sm text-red-600 text-pretty">{error}&nbsp;</p>
            </div>
          </div>

          <div>
            <button
              type="submit"
              disabled={isLoading}
              className={classNames(
                isLoading ? 'cursor-not-allowed animate-pulse bg-primary/70' : 'cursor-pointer',
                'flex w-full justify-center rounded-md bg-primary px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus:ring-primary focus-visible:outline focus-visible:outline-primary focus-visible:outline-offset-2',
              )}
            >
              Entrar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
