interface IProps {
  className?: string | undefined;
}

const BartursIcon = ({ className }: IProps) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 803.000000 149.000000"
      preserveAspectRatio="xMidYMid meet"
      className={className}
    >
      <g transform="translate(0.000000,149.000000) scale(0.100000,-0.100000)" stroke="none">
        <path
          d="M0 744 l0 -745 503 3 c485 4 504 4 567 26 184 62 331 208 392 392 21
    63 22 83 26 568 l3 502 -745 0 -746 0 0 -746z"
        />
        <path
          d="M1720 755 l0 -735 90 0 90 0 0 92 0 91 32 -47 c99 -151 321 -199 503
    -110 107 53 201 182 236 324 20 81 17 298 -5 380 -40 145 -124 253 -243 312
    -64 31 -73 33 -178 33 -92 0 -118 -4 -157 -22 -64 -29 -113 -68 -154 -121
    l-34 -45 0 292 0 291 -90 0 -90 0 0 -735z m623 152 c88 -52 140 -141 159 -276
    30 -214 -59 -412 -203 -455 -223 -67 -399 101 -399 381 0 128 40 235 116 308
    59 58 110 75 205 72 61 -3 85 -9 122 -30z"
        />
        <path
          d="M4798 1228 l-3 -143 -77 -3 -78 -3 0 -74 0 -75 80 0 80 0 0 -364 c0
    -388 2 -409 51 -478 43 -59 193 -103 293 -84 l36 7 0 69 0 70 -50 0 c-28 0
    -66 7 -86 15 -62 26 -64 41 -64 425 l0 340 100 0 100 0 0 75 0 75 -100 0 -100
    0 0 145 0 145 -90 0 -89 0 -3 -142z"
        />
        <path
          d="M3195 1087 c-156 -40 -259 -142 -281 -275 l-7 -42 84 0 84 0 25 52
    c36 76 95 110 199 116 169 10 246 -54 258 -213 l6 -73 -64 -6 c-35 -3 -129
    -10 -209 -15 -167 -12 -240 -32 -314 -89 -76 -57 -101 -114 -101 -227 0 -78 4
    -97 27 -141 46 -88 124 -142 236 -164 146 -29 311 29 384 135 12 19 26 35 31
    35 4 0 7 -36 7 -80 l0 -80 85 0 85 0 0 403 c0 232 -4 417 -10 437 -26 93 -102
    170 -209 211 -69 27 -239 35 -316 16z m359 -654 c-2 -89 -28 -147 -90 -202
    -57 -50 -119 -71 -203 -70 -106 1 -167 34 -198 106 -36 87 17 178 120 208 51
    15 253 34 332 31 l40 -1 -1 -72z"
        />
        <path
          d="M4324 1075 c-61 -30 -84 -54 -118 -120 l-25 -50 -1 88 0 87 -90 0
    -90 0 0 -530 0 -530 90 0 90 0 0 348 c0 392 4 423 66 492 48 53 106 74 189 68
    l65 -4 0 88 0 88 -63 0 c-47 0 -76 -6 -113 -25z"
        />
        <path
          d="M6901 1087 c-50 -19 -108 -73 -136 -125 l-24 -47 -1 83 0 82 -90 0
    -90 0 0 -530 0 -530 90 0 90 0 0 358 c0 403 3 425 70 492 45 45 108 64 190 58
    l61 -4 -3 85 -3 86 -60 2 c-33 1 -75 -4 -94 -10z"
        />
        <path
          d="M7480 1084 c-146 -39 -237 -139 -248 -271 -6 -83 12 -143 60 -198 53
    -60 135 -97 306 -140 79 -19 159 -42 179 -51 68 -33 94 -124 54 -190 -42 -69
    -190 -103 -304 -70 -68 20 -121 68 -138 126 l-12 40 -90 0 -89 0 7 -37 c10
    -60 49 -135 91 -177 119 -119 373 -150 549 -68 128 60 185 143 185 269 0 85
    -17 133 -63 181 -49 52 -117 81 -286 123 -85 22 -171 47 -191 57 -107 56 -104
    189 5 246 48 25 144 32 203 16 60 -16 120 -72 135 -127 l11 -43 84 0 85 0 -7
    47 c-13 93 -81 190 -165 233 -97 49 -250 64 -361 34z"
        />
        <path
          d="M5412 678 c3 -393 4 -404 26 -460 49 -123 158 -201 298 -214 130 -12
    246 28 322 110 23 25 45 46 47 46 3 0 5 -31 5 -70 l0 -70 85 0 85 0 0 530 0
    530 -85 0 -85 0 0 -320 c0 -395 -9 -449 -92 -526 -59 -56 -94 -69 -188 -69
    -97 0 -152 22 -190 75 -46 65 -50 100 -50 482 l0 358 -91 0 -90 0 3 -402z"
        />
      </g>
    </svg>
  );
};
// const BartursIcon = ({ className }: IProps) => {
//   return (
//     <svg
//       xmlns="http://www.w3.org/2000/svg"
//       version="1.0"
//       preserveAspectRatio="xMidYMid meet"
//       viewBox="54 55.3 1247 165"
//       className={className}
//     >
//       <g transform="translate(0.000000,273.000000) scale(0.100000,-0.100000)" stroke="none">
//         <path d="M11460 2174 c-229 -41 -367 -129 -442 -282 -31 -63 -33 -72 -33 -182 0 -103 3 -121 26 -170 31 -66 100 -138 174 -180 69 -39 150 -67 372 -125 281 -75 353 -123 353 -235 0 -117 -114 -191 -296 -193 -108 -1 -211 28 -267 74 -39 32 -87 119 -87 158 0 48 -6 50 -173 53 l-157 3 0 -50 c0 -229 179 -420 448 -478 122 -26 310 -27 427 -3 118 25 234 84 304 153 97 98 138 214 128 365 -7 99 -39 170 -111 247 -83 86 -158 119 -435 191 -128 33 -256 70 -283 82 -70 30 -98 67 -98 129 0 135 120 204 313 179 132 -16 194 -64 224 -171 l16 -59 164 0 163 0 0 33 c0 49 -33 158 -65 213 -60 106 -189 194 -335 229 -64 15 -281 28 -330 19z" />
//         <path d="M540 1361 l0 -781 428 0 c393 0 434 2 511 20 163 39 268 115 328 238 37 75 38 78 38 191 0 98 -4 125 -24 176 -32 81 -126 175 -199 201 -29 10 -52 22 -52 25 0 4 13 12 30 19 16 7 52 35 79 62 69 68 94 136 95 248 1 182 -74 289 -244 351 -64 23 -70 23 -527 27 l-463 3 0 -780z m811 493 c138 -64 126 -279 -18 -328 -37 -13 -90 -16 -250 -16 l-203 0 0 186 0 185 213 -3 c191 -3 217 -5 258 -24z m34 -598 c84 -36 124 -102 125 -201 0 -86 -31 -141 -101 -180 l-54 -30 -235 0 -235 0 -3 205 c-1 112 0 211 3 218 8 23 445 12 500 -12z" />
//         <path d="M2723 2108 c-6 -18 -59 -159 -117 -313 -151 -396 -220 -577 -287 -755 -32 -85 -83 -220 -113 -300 -31 -80 -56 -148 -56 -152 0 -5 76 -8 169 -8 l168 0 21 58 c12 31 40 110 63 175 l41 117 296 -2 295 -3 57 -170 57 -170 176 -3 c97 -1 177 0 177 2 0 4 -483 1298 -535 1431 -12 33 -27 74 -32 90 l-10 30 -180 3 -180 2 -10 -32z m204 -390 c6 -18 50 -145 97 -283 47 -137 86 -251 86 -252 0 -6 -397 -3 -402 2 -3 3 33 112 78 243 109 309 114 322 123 322 4 0 12 -15 18 -32z" />
//         <path d="M4040 2136 c-3 -2 -4 -354 -2 -780 l2 -776 166 2 165 3 2 300 2 300 214 3 c208 2 215 2 267 -22 95 -44 122 -109 144 -351 11 -121 16 -154 31 -203 l11 -32 169 0 170 0 -15 28 c-26 51 -36 107 -46 260 -12 177 -21 224 -57 298 -31 64 -66 98 -128 128 l-47 23 54 30 c91 52 155 138 179 240 15 67 13 199 -4 255 -36 115 -128 215 -244 264 l-68 29 -480 3 c-265 1 -483 1 -485 -2z m862 -288 c63 -38 88 -90 89 -190 1 -113 -29 -166 -116 -205 -33 -14 -76 -18 -248 -21 -115 -2 -219 -1 -233 2 l-24 6 0 214 c0 117 3 216 7 220 4 4 113 6 243 4 l235 -3 47 -27z" />
//         <path d="M5680 2000 l0 -140 233 -2 232 -3 3 -637 2 -638 170 0 170 0 0 640 0 640 235 0 235 0 0 140 0 140 -640 0 -640 0 0 -140z" />
//         <path d="M7363 1573 c3 -491 6 -575 20 -623 47 -157 134 -263 271 -330 108 -53 210 -73 371 -73 151 0 257 19 355 64 195 90 288 235 310 482 5 61 10 322 10 580 l0 467 -175 0 -174 0 -3 -557 -3 -558 -27 -57 c-50 -107 -139 -144 -323 -136 -133 6 -194 37 -248 124 -43 70 -47 124 -47 671 l0 513 -171 0 -170 0 4 -567z" />
//         <path d="M9215 2128 c-3 -7 -4 -357 -3 -778 l3 -765 173 -3 172 -2 0 305 0 306 228 -3 229 -3 42 -28 c80 -53 104 -119 126 -347 8 -85 19 -172 25 -193 l11 -37 170 0 170 0 -16 30 c-22 42 -33 109 -40 246 -15 257 -68 386 -182 436 -24 10 -43 22 -43 26 0 4 28 23 61 42 211 120 239 468 53 654 -50 50 -96 78 -183 107 -50 18 -90 19 -523 19 -366 0 -470 -3 -473 -12z m816 -258 c54 -15 100 -50 123 -95 31 -59 29 -184 -2 -240 -52 -90 -115 -106 -388 -103 l-199 3 -3 223 -2 222 217 0 c120 0 234 -5 254 -10z" />
//         <path d="M12690 694 l0 -167 78 5 c162 10 242 93 242 253 l0 75 -160 0 -160 0 0 -166z" />
//       </g>
//     </svg>
//   );
// };

export default BartursIcon;
