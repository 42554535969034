'use client';
import toast, { ToastOptions } from 'react-hot-toast';

export type TToastType = 'success' | 'error' | 'warning' | 'info';

const toastOptions: ToastOptions = {
  position: 'bottom-right',
  duration: 5000,
  style: {
    fontSize: '14px',
    cursor: 'pointer',
  },
};
export default function notify(message: string, type?: TToastType) {
  switch (type) {
    case 'success':
      toast.custom(
        (t) => (
          <button
            onClick={() => toast.dismiss(t.id)}
            className="flex items-center"
            style={{
              opacity: t.visible ? 1 : 0,
              border: '1px solid #cefffb', // very light green
              padding: '14px',
              backgroundColor: '#cefffb', // very light green
              color: '#16a34a ', // light green
              cursor: 'pointer',
              borderRadius: '0.375rem',
              transition: 'opacity 100ms ease-in-out',
            }}
            title={'Click to dismiss'}
          >
            <svg
              className="w-6 h-6 mr-4"
              fill="none"
              stroke="#16a34a"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
            </svg>
            <span>{message}</span>
          </button>
        ),
        { ...toastOptions },
      );
      break;
    case 'error':
      toast.custom(
        (t) => (
          <div
            onClick={() => toast.dismiss(t.id)}
            className="flex items-center"
            style={{
              opacity: t.visible ? 1 : 0,
              border: '1px solid #fff7f7', // very light red
              padding: '14px',
              backgroundColor: '#fff7f7', // very light red
              color: '#f87171', // light red
              cursor: 'pointer',
              borderRadius: '0.375rem',
              transition: 'opacity 100ms ease-in-out',
            }}
            title={'Click to dismiss'}
          >
            <svg
              className="w-6 h-6 mr-4"
              fill="none"
              stroke="#f87171"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
            <span>{message}</span>
          </div>
        ),
        { ...toastOptions },
      );
      break;
    default:
      toast.custom(
        (t) => (
          <div
            className="flex items-center"
            style={{
              opacity: t.visible ? 1 : 0,
              transition: 'opacity 100ms ease-in-out',
            }}
          >
            <b>{message}</b>
            <button onClick={() => toast.dismiss(t.id)}>X</button>
          </div>
        ),
        { ...toastOptions },
      );
  }
}

// export default function notify(message: string, type?: TToastType) {
//   // Check the type and add the appropriate className
//   switch (type) {
//     case 'success':
//       toast.success(message, { ...toastOptions, className: contextProgressBarClass.success });
//       break;
//     case 'error':
//       toast.error(message, { ...toastOptions, className: contextProgressBarClass.error });
//       break;
//     default:
//       toast.custom(message, { ...toastOptions, className: contextProgressBarClass.info });
//   }
// }
